import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


export const MenuList = () => {
  const data = useStaticQuery(graphql`
    query {
      jsBasic: allMdx(filter: {frontmatter: {type: {eq: "main"}, course: {eq: "js-basic"}}}) {
        nodes {
          slug
          frontmatter {
            title
            lesson
          }
        }
      }
    }
  `)

  return (
    <>
      <h2>Javascript «Базовый уровень»</h2>
      <List sx={{ columns: '300px auto' }} component="ol">
        {data.jsBasic.nodes.map(({ slug, frontmatter }) => (
          <ListItem key={slug}>
            <ListItemText>
              <Typography
                component="span"
                sx={{ mr: '5px'}}
              >
                {frontmatter.lesson}.
              </Typography>
              <Link
                href={`/${slug}`}
                underline="none"
              >
                {frontmatter.title}
              </Link>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  )
}
