import React, { useState } from "react"
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';

import { MenuList } from '@components';


export const Header = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleOpen}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Container
          disableGutters
          maxWidth="md"
          sx={{
            height: '100%',
            p: '0 15px',
            background: 'white'
          }}
        >
          <IconButton
            edge="end"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <MenuList />

        </Container>
      </Modal>
    </>
  )
}
