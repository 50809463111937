import React from "react";
import Box from '@mui/material/Box';


export const Img = (props, ...rest) => {
  console.log('IMG', {props, ...rest});
  return (
    <Box sx={{ textAlign: 'center'}}>
      <img {...props}/>
    </Box>
  )
}
