import React from "react"
import PropTypes from "prop-types";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { Header } from '@components';

export const Layout = ({
  children,
  title,
}) => {
  return (
    <Container
      maxWidth="md"
      disableGutters
    >
      <Header />

      <Box sx={{ p: '0 15px'}}>
        {title && <h1>{title}</h1>}
        {children}
      </Box>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}
